import { Injectable } from '@angular/core';
import { UserService } from 'src/app/auth/services/user.service';
import { environment } from 'src/environments/environment';
import Typesense, { Client } from 'typesense'
import { OrgUser, UserFS } from '../models/user/user';
import { CollectionSchema } from 'typesense/lib/Typesense/Collection';


@Injectable({
  providedIn: 'root'
})
export class TypesenseService {
  private _userClient!: Client;
  private _orgClient!: Client;

  constructor(private userService: UserService) {

    // this.userService.userAsObservable().subscribe((user: UserFS | undefined) => {
    // if (!user || !user.typesenseKey) {
    //   return;
    // }
    // this.setUserClient(user.typesenseKey)
    // this.setUserClient('bX4qWVKk22etWYhvMsmhxHtUXL1r9ZXm');
    // })
    this.setUserClient()

    this.userService.orgUserAsObservable().subscribe((user: OrgUser) => {
      if (!user.typesenseKey) {
        return;
      }
      this.setOrgClient(user.typesenseKey)
    })
  }


  // public setUserClient(apiKey: string): void {    
  public setUserClient(): void {
    this._userClient = new Typesense.Client({
      'apiKey': 'bX4qWVKk22etWYhvMsmhxHtUXL1r9ZXm',
      'nodes': [{
        'host': environment.typesenseHost,
        'port': environment.typesensePort,
        'protocol': environment.typesenseProtocol
      }],
      'connectionTimeoutSeconds': 10,
      logLevel: "error"
    })
  }


  public setOrgClient(apiKey: string): void {

    this._orgClient = new Typesense.Client({
      'apiKey': apiKey,
      'nodes': [{
        'host': environment.typesenseHost,
        'port': environment.typesensePort,
        'protocol': environment.typesenseProtocol
      }],
      'connectionTimeoutSeconds': 10,
      logLevel: "error"
    })
  }

  get userClient(): Client {
    return this._userClient;
  }

  get orgClient(): Client {
    return this._orgClient;
  }

}
