import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { OrderArticle } from "src/app/core/models/order/orderArticle";
import { TypesenseService } from "src/app/core/services/typesense.service";
import { environment } from "src/environments/environment";
import { SearchParams, SearchResponse } from "typesense/lib/Typesense/Documents";

export class ArticlesTypesenseResponse {
    found: number;
    out_of: number;
    currentPage: number;
    totalPages: number;
    results: ArticlesTS[]

    constructor(found: number, currentPage: number, out_of: number, itemPerPage: number, customersTS: ArticlesTS[]) {
        this.out_of = out_of;
        this.found = found;
        this.currentPage = currentPage;
        this.results = customersTS
        this.totalPages = Math.ceil(found / itemPerPage)
    }
}

export class ProcArticlesTypesenseResponse {
    map(arg0: (articleFromCatalog: any) => import("src/app/core/models/order/orderArticle").OrderArticle) {
        throw new Error("Method not implemented.");
    }
    found: number;
    out_of: number;
    currentPage: number;
    totalPages: number;
    results: OrderArticle[]

    constructor(found: number, currentPage: number, out_of: number, itemPerPage: number, article: OrderArticle[]) {
        this.out_of = out_of;
        this.found = found;
        this.currentPage = currentPage;
        this.results = article
        this.totalPages = Math.ceil(found / itemPerPage)
    }
}



export class CatalogTypesenseObject {

    catalogId: string;
    code: string;
    id: string;
    name: string;
    orgId: string;
    document: any;


    //constructor(catalogId: string, code: string, id: string, name: string, orgId: string) {
    constructor(obj?: any) {
        this.catalogId = obj && obj.catalogId;
        this.code = obj && obj.code;
        this.id = obj && obj.id;
        this.name = obj && obj.name
        this.orgId = obj && obj.orgId
    }
}


export class ProcArticlesTS {
    id: string;
    name: string;
    code: string;
    orgId: string;
    unitOfMeasurement: string;
    packageQuantity: number;
    categoryName: string;
    imageUrl: string;

    constructor(obj?: any) {
        this.id = obj && obj.id || ""
        this.name = obj && obj.name || "";
        this.code = obj && obj.code || "";
        this.orgId = obj && obj.orgId || "";
        this.unitOfMeasurement = obj && obj.unitOfMeasurement || "kom";
        this.packageQuantity = obj && obj.packageQuantity || 0;
        this.orgId = obj && obj.orgId || "";
        this.categoryName = obj && obj.categoryName || "";
        this.imageUrl = obj && obj.imageUrl || "";
    }
}

export class ArticlesTS {
    id: string;
    name: string;
    code: string;
    catalogId: string;
    isInactive: boolean;

    constructor(obj?: any) {
        this.id = obj && obj.id || ""
        this.name = obj && obj.name || "";
        this.code = obj && obj.code || "";
        this.catalogId = obj && obj.catalogId || "";
        this.isInactive = obj && obj.isInactive || false;
    }
}

@Injectable({
    providedIn: 'root'
})
export class ArticlesTypesense {

    protected path = `${environment.production ? 'prod' : 'stage'}_organization_master_articles`

    constructor(private typesense: TypesenseService) { }

    searchPromise(search: SearchParams): Promise<ArticlesTypesenseResponse> {
        console.log("path typesnes artikala:", this.path);
        return new Promise((resolve, reject) => {
            this.typesense.orgClient.collections(this.path).documents().search(search)
                .then((response: SearchResponse<{}>) => {
                    if (!response.hits) {
                        resolve(new ArticlesTypesenseResponse(0, 0, 0, 0, []));
                        return;
                    }
                    var searchHits: ArticlesTS[] = [];
                    response.hits.map((elem: any) => searchHits.push(new ArticlesTS(elem.document)));
                    resolve(new ArticlesTypesenseResponse(response.found, response.page, response.out_of, search.per_page ?? 20, searchHits));
                })
                .catch((err: any) => reject(err));
        });
    }

    searchObserver(search: SearchParams): Observable<ArticlesTypesenseResponse> {
        const searchSubject = new Subject<ArticlesTypesenseResponse>()

        this.typesense.orgClient.collections(this.path).documents().search(search)
            .then((response: SearchResponse<{}>) => {
                console.log("response sa servera:", response)
                if (!response.hits) {
                    searchSubject.next(new ArticlesTypesenseResponse(0, 0, 0, 0, []))
                    return
                }
                var searchHits: ArticlesTS[] = [];
                response.hits.map((elem: any) => searchHits.push(new ArticlesTS(elem.document)))
                console.log("searchHITD:", searchHits)
                searchSubject.next(new ArticlesTypesenseResponse(response.found, response.page, response.out_of, search.per_page ?? 20, searchHits));

                searchSubject.complete()
            }).catch((err: any) => searchSubject.error(err))
        return searchSubject.asObservable();
    }

    // async searchAsync(search: SearchParams): Promise<ArticlesTypesenseResponse> {
    //     return new Promise<ArticlesTypesenseResponse>((resolve, reject) => {
    //         this.typesense.orgClient.collections(this.path).documents().search(search)
    //             .then((response: SearchResponse<{}>) => {
    //                 if (!response.hits) {
    //                     resolve(new ArticlesTypesenseResponse(0, 0, 0, 0, []));
    //                 } else {
    //                     const searchHits: ArticlesTS[] = response.hits.map((elem: any) => new ArticlesTS(elem.document));
    //                     resolve(new ArticlesTypesenseResponse(response.found, response.page, response.out_of, search.per_page ?? 20, searchHits));
    //                 }
    //             })
    //             .catch((err: any) => reject(err));
    //     });
    // }


    //privremeno stavljeni strand artikli da se testira. ovi artikli se merguju sa katalog artiklima
    // searchSupplierPublicArticlesCatalogPromise(search: SearchParams): Promise<ProcArticlesTypesenseResponse> {
    searchSupplierCatalogArticlesPromise(search: SearchParams): Promise<ProcArticlesTypesenseResponse> {
        return new Promise((resolve, reject) => {
            // this.typesense.userClient.collections('prod_procurement_supplier_public_articles').documents().search(search)
            this.typesense.userClient.collections('stage_procurement_supplier_catalogs').documents().search(search)
                .then((response: SearchResponse<{}>) => {
                    if (!response.hits) {
                        resolve(new ProcArticlesTypesenseResponse(0, 0, 0, 0, []))
                        return;
                    }
                    var searchHits: OrderArticle[] = [];
                    // var searchHitsCatalogs: CatalogTypesenseObject[] = [];

                    response.hits.map((elem: any) => searchHits.push(new OrderArticle(elem.document)));
                    // response.hits.map((elem: any) => searchHitsCatalogs.push(new CatalogTypesenseObject(elem.document)));
                    // console.log("odgovor searchHitsCatalogs:", searchHitsCatalogs)

                    //ovde treba izvuci artikle samo iz kataloga za koje postoji opcija
                    //to posle mergovati u artikle iz mastera

                    resolve(new ProcArticlesTypesenseResponse(response.found, response.page, response.out_of, search.per_page ?? 20, searchHits));
                }).catch((error: any) => {
                    console.error(`An error occurred during the search: ${error}`);
                });
        });
    }

    searchCustomerMasterArticlesCatalogPromise(search: SearchParams): Promise<ProcArticlesTypesenseResponse> {
        return new Promise((resolve, reject) => {
            this.typesense.userClient.collections('prod_procurement_customer_master_articles').documents().search(search)
                .then((response: SearchResponse<{}>) => {
                    if (!response.hits) {
                        resolve(new ProcArticlesTypesenseResponse(0, 0, 0, 0, []))
                        return;
                    }
                    var searchHits: OrderArticle[] = [];
                    response.hits.map((elem: any) => searchHits.push(new OrderArticle(elem.document)));
                    resolve(new ProcArticlesTypesenseResponse(response.found, response.page, response.out_of, search.per_page ?? 20, searchHits));
                })
                .catch((err: any) => reject(err));
        });
    }


}
