<div class="cards-container">
    <p>{{requestedDeliveryDate}}</p>
    <mat-card appearance="outlined">
        <mat-form-field>
            <mat-label>Datum isporuke:</mat-label>

            <input matInput [matDatepicker]="picker" [ngModel]="requestedDeliveryDate"
                (ngModelChange)="setDate($event)"><!--  -->
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <div class="spacer">
            <mat-slide-toggle [ngModel]="deliverAsSoonAsPossible" (ngModelChange)="delivery($event)">Što
                pre</mat-slide-toggle>
            <!-- <button mat-raised-button *ngIf="!editPrice" (click)="editPrices()">Izmeni cene</button> -->
            <!-- <button mat-raised-button color="primary" *ngIf="editPrice"
                (click)="saveFavouriteArticles(order[0].products); editPrices()">Sačuvaj
                cene</button> -->
        </div>
    </mat-card>
</div>