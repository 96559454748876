import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-delivery-time',
  templateUrl: './delivery-time.component.html',
  styleUrls: ['./delivery-time.component.sass']
})
export class DeliveryTimeComponent implements OnInit {

  constructor() { }

  editPrice: boolean = false;

  @Input() requestedDeliveryDate!: Date
  @Input() deliverAsSoonAsPossible!: Boolean

  @Output() setRequestedDeliveryDate = new EventEmitter()
  @Output() switchDeliveryAsSoonAsPossible = new EventEmitter()

  ngOnInit(): void {
  }

  setDate(requestedDeliveryDate: any){
    this.setRequestedDeliveryDate.emit(requestedDeliveryDate)
  }

  delivery(switcher: boolean){
    this.switchDeliveryAsSoonAsPossible.emit(switcher)
  }


}
