import { Injectable } from "@angular/core";
// import { NgxsFirestoreConnect, StreamEmitted, Emitted } from "@ngxs-labs/firestore-plugin";
import { Action, NgxsOnInit, Selector, State, StateContext, Store, createSelector } from "@ngxs/store";
import { patch, append } from "@ngxs/store/operators";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { UserService } from "src/app/auth/services/user.service";
import { Article, IArticle } from "src/app/core/models/article/article";
import { ArticleReservations } from "src/app/core/models/article/articleReservations";
import { OrgUser } from "src/app/core/models/user/user";
import { ArticlesActions } from "src/app/shared/states/articles/articles.action";
import { ArticlesTypesense, ArticlesTS, ArticlesTypesenseResponse } from "./articles.typesense";
// import { ArticlesFirestore } from "./firestore/articles.firestore";
// import { ReservationsFirestore } from "./firestore/reservations.firestore";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { CustomerTypesenseResponse } from "../customers/customers.typesense";
import { firstValueFrom, forkJoin, of } from "rxjs";
import { GlobalActions } from "src/app/core/actions/global.actions";

export class ArticlesStateModel {
  articlesBucket: { [id: string]: Article } = {};
  articlesReservations: ArticleReservations = new ArticleReservations();
  searchResult: Array<string> = [];
  totalFound: number = 0;
  totalPages: number = 0;
  outOf: number = 0;
  currentPageLoaded: number = 0;
}

@State<ArticlesStateModel>({
  name: 'articlesState',
  defaults: {
    articlesBucket: {},
    articlesReservations: new ArticleReservations(),
    searchResult: [],
    totalFound: 0,
    totalPages: 0,
    outOf: 0,
    currentPageLoaded: 0
  }
})
@Injectable()
export class ArticlesState implements NgxsOnInit {

  orgId: string = "";

  constructor(
    // private articlesFS: ArticlesFirestore,
    // private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private userService: UserService,
    private store: Store,
    private typesense: ArticlesTypesense,
    // private reservationsFS: ReservationsFirestore,
    private db: AngularFirestore
  ) {

  }

  ngxsOnInit() {
    this.userService.orgUserAsObservable().subscribe(
      (user: OrgUser | undefined) => {
        if (!user || !user.id) {
          return;
        }

        this.orgId = user.orgId;
        // this.articlesFS.setOrgId(user.orgId)
        // this.reservationsFS.setOrgId(user.orgId)

        // this.ngxsFirestoreConnect.connect(ArticlesActions.ConnectArticle, {
        //   to: (action: ArticlesActions.ConnectArticle) => {
        //     return this.articlesFS.doc$(action.payload)
        //   },
        //   trackBy: ({ payload }) => `Id ${payload}`,
        //   cancelPrevious: false
        // })

        // this.ngxsFirestoreConnect.connect(ArticlesActions.ConnectReservations, {
        //   to: (action: ArticlesActions.ConnectReservations) => {
        //     return this.reservationsFS.doc$('inbound_orders')
        //   }
        // })

      }
    )
  }

  @Action(ArticlesActions.LoadProcurementMasterArticlesFromTypesense)
  LoadProcurementMasterArticlesFromTypesense(ctx: StateContext<ArticlesStateModel>,
    { payload }: ArticlesActions.SearchArticles) {
    const bucket = ctx.getState().articlesBucket;

    return this.typesense.searchObserver(payload)
      .pipe(
        tap((searchResponse: ArticlesTypesenseResponse) => {
          ctx.setState(patch({
            searchResult: [],
            totalPages: searchResponse.totalPages,
            totalFound: searchResponse.found,
            outOf: searchResponse.out_of,
            currentPageLoaded: searchResponse.currentPage
          }))
        }),

        tap((searchResponse: ArticlesTypesenseResponse) => {
          var _constOfArticleIds: string[] = []
          searchResponse.results.forEach((article: ArticlesTS) => {
            if (!bucket[article.id]) {
              //this.store.dispatch(new ArticlesActions.ConnectArticle(article.id))
              _constOfArticleIds.push(article.id)
            }
          })
          this.store.dispatch(new ArticlesActions.ConnectAllArticles(_constOfArticleIds))
        })

      ).subscribe()
  }

  @Action(ArticlesActions.SearchArticles)
  async searchArticles(ctx: StateContext<ArticlesStateModel>, { payload }: ArticlesActions.SearchArticles) {
    const bucket = ctx.getState().articlesBucket;
    const searchResponse = await this.typesense.searchPromise(payload)

    const _articleIds = searchResponse.results.map((article: ArticlesTS) => article.id)

    ctx.setState(patch({
      searchResult: _articleIds,
      totalPages: searchResponse.totalPages,
      totalFound: searchResponse.found,
      outOf: searchResponse.out_of,
      currentPageLoaded: searchResponse.currentPage
    }))


    const missedArticles = searchResponse.results
      .map((article: ArticlesTS) => article.id)
      .filter(id => !bucket[id]);

    this.store.dispatch(new ArticlesActions.ConnectAllArticles(missedArticles))
  }

  // @Action(ArticlesActions.SearchArticles)
  // searchArticles(ctx: StateContext<ArticlesStateModel>, { payload }: ArticlesActions.SearchArticles) {
  //   const bucket = ctx.getState().articlesBucket;
  //   console.log("OVDE NAKON CUVANJA")
  //   const sub = this.typesense.search(payload)
  //     .pipe(
  //       tap(() => {
  //         ctx.patchState({ searchResult: [] })
  //       }),
  //       tap((searchResponse: ArticlesTypesenseResponse) => {
  //         ctx.setState(patch({
  //           searchResult: append(searchResponse.results.map((article: ArticlesTS) => article.id)),
  //           totalPages: searchResponse.totalPages,
  //           totalFound: searchResponse.found,
  //           outOf: searchResponse.out_of,
  //           currentPageLoaded: searchResponse.currentPage
  //         }))
  //       }),
  //       tap((searchResponse: ArticlesTypesenseResponse) => {
  //         searchResponse.results.forEach((article: ArticlesTS) => {
  //           if (!bucket[article.id]) {
  //             this.store.dispatch(new ArticlesActions.ConnectArticle(article.id))
  //           }
  //         })
  //       }),
  //       tap(() => {
  //         sub.unsubscribe()
  //       })
  //     ).subscribe()
  // }

  @Action(ArticlesActions.ConnectAllArticles)
  connectArticles(ctx: StateContext<ArticlesStateModel>, action: ArticlesActions.ConnectAllArticles) {
    // Create an array of observables for each article fetch operation
    const articleFetchObservables = action.articleIds.map(articleId =>
      this.db.doc<Article>(`organizations/${this.orgId}/catalogs/master/articles/${articleId}`)
        .get()
        .pipe(
          catchError(error => {
            console.error(`Error fetching article with ID ${articleId}:`, error);
            return of(null); // Return null in case of error, or handle as appropriate.
          })
        )
    );

    // Use forkJoin to wait for all observables to complete
    return forkJoin(articleFetchObservables).pipe(
      map(results => {
        // results will be an array of fetched articles or nulls (in case of errors)

        const _changes: { [id: string]: Article } = {};

        const changes = results.reduce((acc, result, index) => {
          if (result && result.exists) {
            const loadedArticle = new Article(result.data());
            _changes[loadedArticle.id] = loadedArticle
          }
          return _changes;
        }, {});

        // Update the state with the loaded articles
        ctx.setState(patch({
          articlesBucket: patch(changes)
        }));
      }),
      catchError(error => {
        console.error('An error occurred while fetching articles:', error);
        // Handle the error appropriately, maybe setting an error state
        return []; // Return an empty array or handle as appropriate.
      })
    );
  }

  @Action(ArticlesActions.ConnectArticle)
  connectArticle(ctx: StateContext<ArticlesStateModel>, action: ArticlesActions.ConnectArticle) {
    console.log(` uzixwma detalje artikla sa: organizations/${this.orgId}/catalogs/master/articles/${action.articleId}`)
    return this.db.doc<Article>(`organizations/${this.orgId}/catalogs/master/articles/${action.articleId}`)
      .get()
      .pipe(tap(result => {
        console.log("dobija artikal iz favorita:", result.data())
        const changes: { [id: string]: Article } = {};
        if (result.data() != undefined) {
          //changes[action.articleId] =      
          var loadedArticle = new Article(result.data())
          changes[action.articleId] = loadedArticle
          ctx.setState(patch({
            articlesBucket: patch(changes)
          }))
        }
      }));
  }

  @Action(ArticlesActions.UpdateStateArticle)
  updateStateArticles(ctx: StateContext<ArticlesStateModel>, action: ArticlesActions.UpdateStateArticle) {

    const changes: { [id: string]: Article } = {};

    // action.articles.forEach((articleItem) => {
    changes[action.article.id] = action.article;
    // });

    // changes[action.articleId] = loadedArticle
    ctx.setState(patch({
      articlesBucket: patch(changes)
    }))

  }




  @Action(ArticlesActions.ConnectReservations)
  connectReservations(ctx: StateContext<ArticlesStateModel>,
    action: ArticlesActions.ConnectReservations) {

    return this.db.doc<ArticleReservations>(`organizations/${this.orgId}/reservations/inbound_orders`)
      .get()
      .pipe(tap(result => {
        const changes: { [id: string]: Article } = {};
        if (result.data() != undefined) {
          ctx.patchState({ articlesReservations: result.data() })
        }
      }));

    // throw new Error('RXJS not implemented yet');   
    // ctx.patchState({ articlesReservations: new ArticleReservations(payload) })
  }

  @Selector()
  static getSearchedArticles(state: ArticlesStateModel) {
    return state.searchResult.map((id: string) => state.articlesBucket[id])
  }

  @Selector()
  static getArticlesReservations(state: ArticlesStateModel) {
    return state.articlesReservations
  }

  @Selector()
  static getArticlesBucket(state: ArticlesStateModel) {
    return state.articlesBucket
  }

  @Selector()
  static getArticles(state: ArticlesStateModel) {
    return Object.values(state.articlesBucket)
  }

  @Selector()
  static getArticle(state: ArticlesStateModel) {
    return (id: string) => {
      //console.log(id)
      if (!state.articlesBucket[id]) {
        return new ArticlesActions.ConnectArticle(id)
      } else {
        return state.articlesBucket[id]
      }
    }
  }

  //   static getAllArticlesFromStateonlyOnce() {
  //     return createSelector([ArticlesState], (state: ArticlesStateModel) => {       
  //       return Object.values(state.articlesBucket)      
  //     })  
  // }

  static getAllArticlesFromStateonlyOnce() {
    return createSelector([ArticlesState], (state: ArticlesStateModel) => {
      // return state.articlesBucket['2']
      return Object.values(state.articlesBucket)
    })
  }

  @Selector()
  static getTotalFoundedArticles(state: ArticlesStateModel) {
    return state.totalFound
  }

  @Selector()
  static getTotalLoadedArticles(state: ArticlesStateModel) {
    return Object.keys(state.articlesBucket).length
  }

  @Action(GlobalActions.ClearAll)
  clearState(ctx: StateContext<ArticlesStateModel>, { }: GlobalActions.ClearAll) {
    ctx.setState({
      articlesBucket: {},
      articlesReservations: new ArticleReservations(),
      searchResult: [],
      totalFound: 0,
      totalPages: 0,
      outOf: 0,
      currentPageLoaded: 0
    })
  }
}
